const localhostClient = "aceleracredvalor";

export const isLocal =
  window.location.origin.search(/(localhost)|(([0-9]+.)+:[0-9]+)/g) > -1;

export const getClient = () => {
  const host = window.location.host;
  if (isLocal) {
    return localhostClient;
  }
  switch (host) {
    case "onbook.martins.com.br":
      return "onbook";
    case "unimarche.marche.com.br":
      return "unimarche";
    default:
      const client = isLocal
        ? localhostClient
        : host
            .replace("www.", "")
            .replace("cadernovirtual", "")
            .replace("basf-academiaagro", "")
            .replace(".com.br", "")
            .replace(".", "")
            .replace("beta", "");
      return client;
  }
};
