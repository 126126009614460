import { Container } from "@mui/material";
import AttachmentContent from "./AttachmentContent";
import AttachmentInput from "./AttachmentInput";

function AttachmentComponent(props) {
  const { value, onChange, isEditing = false } = props;

  function handleRemove(index) {
    const newValue = [...value];

    if (newValue[index].isValid === true) {
      newValue[index].condition = "removed";
    } else {
      newValue.splice(index, 1);
    }

    onChange([...newValue]);
  }

  function setMandatory(index, newMandatory) {
    const newValue = [...value];

    newValue[index].mandatory = newMandatory;

    if (newValue[index].isValid) {
      newValue[index].modified = true;
    }

    onChange([...newValue]);
  }

  return (
    <Container sx={{ display: "flex", flexDirection: "column" }}>
      <AttachmentContent
        {...props}
        removeAttachment={handleRemove}
        setMandatory={setMandatory}
      />
      {isEditing && <AttachmentInput value={value} onChange={onChange} />}
    </Container>
  );
}

export default AttachmentComponent;
