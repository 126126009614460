import {
  Box,
  Grid,
  Link,
  Card as MuiCard,
  CardContent,
  Typography,
  CardActionArea as MuiCardActionArea,
  // CardActionArea,
  CardMedia,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import ImageWithBackdrop from "../ImageWithBackdrop";
import { More, MoreVert } from "@mui/icons-material";
import { useState } from "react";
const CardActionArea = styled(MuiCardActionArea)(({ theme }) => ({
  "&:hover #optionsButton": {
    backgroundColor: theme.palette.grey["200"],
  },
}));

const Card = styled(MuiCard)(({ theme }) => ({
  position: "relative",
  "&:hover #optionsButton": {
    backgroundColor: theme.palette.grey["200"],
  },
}));

export default function GridListItem({
  label = null,
  subLabel = null,
  additionalContent = null,
  imageUrl = null,
  footer = null,
  ActionAreaProps = {},
  LabelProps = {},
  SubLabelProps = {},
  CardProps = {},
  CardContentProps = {},
  CardMediaProps = {},
  options = () => {},
  enableOptions = false,
}) {
  const [optionsMenuEl, setOptionsMenuEl] = useState(null);
  const optionsMenuOpen = Boolean(optionsMenuEl);
  const handleClickOptionsButton = (event) => {
    setOptionsMenuEl(event.currentTarget);
  };
  const handleCloseOptions = (event) => {
    setOptionsMenuEl(null);
  };
  return (
    <Grid item>
      <Card sx={{ height: "100%", positio: "relative" }} {...CardProps}>
        <CardActionArea {...ActionAreaProps}>
          {!!imageUrl && (
            <CardMedia {...CardMediaProps}>
              <Box
                sx={{
                  aspectRatio: "16 / 9",
                  width: "100%",
                  position: "relative",
                  // height: "fit-content",
                }}
              >
                <ImageWithBackdrop url={imageUrl} />
              </Box>
            </CardMedia>
          )}
          {(label || subLabel) && (
            <CardContent {...CardContentProps}>
              <Tooltip title={label}>
                <Typography
                  sx={{ fontSize: "1rem", fontWeight: "500" }}
                  noWrap
                  gutterBottom
                  // component="h6"
                  color="primary"
                  {...LabelProps}
                >
                  {label}
                </Typography>
              </Tooltip>

              <Tooltip title={subLabel}>
                <Typography
                  noWrap
                  variant="caption"
                  color="text.secondary"
                  {...SubLabelProps}
                >
                  {subLabel}
                </Typography>
              </Tooltip>
              {additionalContent}
            </CardContent>
          )}
        </CardActionArea>

        {enableOptions && (
          <>
            <IconButton
              id="optionsButton"
              sx={{ position: "absolute", top: 3, right: 3 }}
              onClick={handleClickOptionsButton}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={optionsMenuEl}
              open={optionsMenuOpen}
              onClose={handleCloseOptions}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {options({ closeMenu: handleCloseOptions }).map(
                (optionContent) => {
                  return optionContent;
                }
              )}
            </Menu>
          </>
        )}
        {footer}
      </Card>
    </Grid>
  );
}
