import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { axios as axios2 } from "../../../shared/lib/axios";
import { v4 as uuidv4 } from "uuid";
import _cookies from "../../../shared/utils/_cookies";

export const uploadPandaVideo = async (data) => {
  const id = uuidv4();
  const fileBlob = new Blob([data.file], { type: data.file?.type });

  const res = await axios.post(
    "https://uploader-us01.pandavideo.com.br/files",
    fileBlob,
    {
      headers: {
        "Tus-Resumable": "1.0.0",
        "Upload-Length": `${data.file.size}`,
        "Content-Type": "application/offset+octet-stream",
        "Upload-Metadata": `authorization ${btoa(
          "panda-9cabbff7fbffa44b2a69d44aa89db0d6067cf3f6feaaaf4c4d12101d89497623"
        )}, filename ${btoa(data.title)}, video_id ${btoa(
          id
        )}, folder_id ${btoa(data.folderId)}`,
      },
    }
  );
  const res2 = await axios2.cadernoVirtualBase.post(
    "https://api.cadernovirtual.com.br/video-service/video",
    {
      name: data.title,
      userId:
        _cookies.getItem("auth").user?.profile?.isProfessor?.ID ||
        encodeURIComponent(_cookies.getItem("auth").user.nome),
      videoId: id,
      albumId: data.albumId,
      folderId: data.folderId,
    }
  );
  return res2;
};

export const useUploadPandaVideo = ({ config } = {}) => {
  return useMutation({ ...config, mutationFn: uploadPandaVideo });
};
