import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import { useTenantParameters } from "./getTenantParameters";
import _cookies from "../../../shared/utils/_cookies";

export const getPandaVideos = async (params = {}) => {
  const res = await axios.cadernoVirtualBase(
    "https://api.cadernovirtual.com.br/video-service/video",
    {
      params,
    }
  );
  console.log(res);

  return res;
};

export const usePandaVideos = ({ config, params } = {}) => {
  const tenantParams = useTenantParameters();
  const { myVideos, page, itensPerPage } = params;

  return useQuery({
    ...config,
    enabled: tenantParams.isSuccess,
    queryKey: ["pandaVideos", params],
    queryFn: () =>
      getPandaVideos({
        ...params,
        albumId: tenantParams?.data?.VIMEO_ALBUMID,
        page,
        user:
          _cookies.getItem("auth").user?.profile?.isProfessor?.ID ||
          encodeURIComponent(_cookies.getItem("auth").user.nome),
        pageSize: itensPerPage,
        my_videos: myVideos,
      }),
  });
};
