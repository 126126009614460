import axios from "axios";
import { courseApi } from "../api/courseApi";
import { getClient } from "../config/urlConfig";

const SAVE_CHAPTER = "SAVE_CHAPTER";
const REMOVE_CHAPTER = "REMOVE_CHAPTER";
const CHANGE_CHAPTER = "CHANGE_CHAPTER";
const SELECT_FETCHED_CHAPTER = "SELECT_FETCHED_CHAPTER";
const SELECT_UNFETCHED_CHAPTER = "SELECT_UNFETCHED_CHAPTER";
const UPDATE_CHAPTER = "chapter/updated";
const CREATE_CONTENT = "CREATE_CONTENT";
const SAVE_CONTENT = "SAVE_CONTENT";
const REMOVE_CONTENT = "REMOVE_CONTENT";
const MOVE_CHAPTER = "chapter/order";
export const MOVE_CHAPTER_UP = "chapter/order_up";
export const MOVE_CHAPTER_DOWN = "chapter/order_down";
const LOAD_SCORM_PACKAGES = "LOAD_SCORM_PACKAGES";
const SET_LOADING = "setLoading";
const CLEAR_LOADING = "clearLoading";

const domain = getClient();

function hasNoChapter(selectedChapter) {
  return !selectedChapter;
}

async function fetchChapterContent(capituloid, token) {
  const api = courseApi.chapter_all_content;

  try {
    const header = new Headers();
    header.append("Access-Control-Allow-Origin", "*");
    header.append("domain", domain);

    const response = await fetch(
      `${api.url}?${api.queryParams}=${capituloid}`,
      {
        method: "GET",
        headers: header,
      }
    );

    const chapterJson = await response.json();

    chapterJson.forEach((content, index) => {
      if (content.contentType === "ANEXO" || content.contentType === "IMAGEM") {
        if (content?.resource?.length > 0) {
          chapterJson[index].content = content.resource.map((item) => ({
            ...item,
            RECURSOID: item.resourceId,
            CAPITULOID: item.chapterContentId,
            NOME: item.name,
            LINK: item.link,
            EXTENSAO: item.link?.split(".")?.at(-1) ?? "url",
            isValid: true,
          }));

          delete chapterJson[index].resource;
        }
      }
    });

    return chapterJson;
  } catch (error) {
    console.error("Erro ao buscar dados:", error);
  }
}

function formatTodayDate() {
  const now = new Date();

  const day = now.getDate().toString().padStart(2, "0"),
    month = (now.getMonth() + 1).toString().padStart(2, "0"),
    year = now.getFullYear().toString(),
    hours = now.getHours().toString().padStart(2, "0"),
    minutes = now.getMinutes().toString().padStart(2, "0"),
    seconds = now.getSeconds().toString().padStart(2, "0");
  return [
    day,
    "/",
    month,
    "/",
    year,
    " ",
    hours,
    ":",
    minutes,
    ":",
    seconds,
  ].join("");
}

export const updateSurveyId =
  (chapterId, surveyId) => async (dispatch, getState) => {
    const currentList = getState().editing.chapterList;
    const chapterIndex = currentList.findIndex(
      (chapter) => chapter.chapterId == chapterId
    );

    currentList[chapterIndex] = {
      ...currentList[chapterIndex],
      surveyId,
    };

    dispatch({
      type: SELECT_UNFETCHED_CHAPTER,
      selectedChapterId: chapterId,
      chapterList: [...currentList],
      loading: false,
    });
  };

export const saveChapter = ({
  courseId,
  name = "novo capítulo",
  order,
  type,
  postDate = formatTodayDate(),
  chapterOrigin = null,
  tags = "",
  daysToRelease = 0,
  daysToExpire = 0,
  chapterId = null,
  selectAfterSave = false,
  scormId = null,
  isChannel = false,
  isNews = false,
  origin,
  SITUACAO,
}) => {
  return async (dispatch, getState) => {
    try {
      const api = courseApi.save_chapter;
      const currentList = getState().editing.chapterList ?? [];
      const payload = {
        courseId,
        name,
        order,
        type,
        postDate,
        chapterOrigin,
        tags,
        daysToRelease,
        daysToExpire,
        scormId,
        origin,
        SITUACAO,
      };

      if (chapterId !== null) {
        payload.chapterId = chapterId;
      }

      dispatch({ type: SET_LOADING });

      const response = await fetch(api.url, {
        method: api.method,
        body: JSON.stringify(payload),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          domain,
        },
      });

      const data = await response.json();

      const newList = [...currentList];

      if (chapterId === null) {
        newList.push({
          chapterId: data.id,
          contents: [],
          ...data,
        });
      } else if (
        !!currentList?.find((chapter) => chapter.chapterId === chapterId)
      ) {
        const allChapters = isChannel
          ? getState().channel.items
          : getState().course.chapters;

        const chapterInfo = isChannel
          ? allChapters.find(({ CAPITULOID }) => CAPITULOID === chapterId)
          : allChapters.find(({ capituloid }) => capituloid === chapterId);

        const typeChanged = chapterInfo.tipo != type;

        const loadedChapter = await fetchChapterContent(chapterId, null);

        const chapterIndex = newList.findIndex(
          (chapter) => chapter.chapterId === chapterId
        );

        newList[chapterIndex] = {
          chapterId,
          contents: [...loadedChapter],
          type,
          surveyId: typeChanged ? null : chapterInfo?.avaliacaoid,
          scormPackageId: typeChanged ? null : chapterInfo?.scorm_pacoteid,
        };
      }

      dispatch({
        type: SAVE_CHAPTER,
        chapterList: newList,
        selectedChapterId: selectAfterSave ? data.id : null,
      });

      if (selectAfterSave) {
        const token = getState().auth.token;

        setSelectedChapter({ chapterId: data.id, token, isChannel, isNews })(
          dispatch,
          getState
        );
      }

      return { status: 200 };
    } catch (error) {
      console.error(error);
      return { status: 500 };
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };
};

export const saveChapterV2 = ({
  courseId,
  name = "novo capítulo",
  order,
  type,
  postDate = formatTodayDate(),
  chapterOrigin = null,
  tags = "",
  daysToRelease = 0,
  daysToExpire = 0,
  chapterId = null,
  selectAfterSave = false,
  scormId = null,
  isChannel = false,
  isNews = false,
  origin,
  SITUACAO,
  link,
}) => {
  return async (dispatch, getState) => {
    try {
      const api = courseApi.save_chapter_v2;
      const currentList = getState().editing.chapterList ?? [];
      const payload = {
        courseId,
        name,
        order,
        type,
        postDate,
        chapterOrigin,
        tags,
        daysToRelease,
        daysToExpire,
        scormId,
        origin,
        SITUACAO,
        link,
      };

      if (chapterId !== null) {
        payload.chapterId = chapterId;
      }

      dispatch({ type: SET_LOADING });

      const response = await fetch(api.url, {
        method: api.method,
        body: JSON.stringify(payload),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          domain,
        },
      });

      const data = await response.json();
      const newList = [...currentList];

      if (chapterId === null) {
        newList.push({
          chapterId: data.id,
          surveyId: data.exam,
          contents: [],
          ...data,
        });
      } else if (
        !!currentList?.find((chapter) => chapter.chapterId === chapterId)
      ) {
        const allChapters = isChannel
          ? getState().channel.items
          : getState().course.chapters;

        const chapterInfo = isChannel
          ? allChapters.find(({ CAPITULOID }) => CAPITULOID === chapterId)
          : allChapters.find(({ capituloid }) => capituloid === chapterId);

        const typeChanged = chapterInfo.tipo != type;

        const loadedChapter = await fetchChapterContent(chapterId, null);

        const chapterIndex = newList.findIndex(
          (chapter) => chapter.chapterId === chapterId
        );

        newList[chapterIndex] = {
          chapterId,
          contents: [...loadedChapter],
          type,
          surveyId: typeChanged ? null : chapterInfo?.avaliacaoid,
          scormPackageId: typeChanged ? null : chapterInfo?.scorm_pacoteid,
        };
      }

      dispatch({
        type: SAVE_CHAPTER,
        chapterList: newList,
        selectedChapterId: selectAfterSave ? data.id : null,
      });

      if (selectAfterSave) {
        const token = getState().auth.token;

        setSelectedChapter({ chapterId: data.id, token, isChannel, isNews })(
          dispatch,
          getState
        );
      }

      return { status: 200 };
    } catch (error) {
      console.error(error);
      return { status: 500 };
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };
};

// export const saveChapter = ({
//   token,
//   index,
//   chapterId,
//   courseId,
//   name,
//   order,
//   postDate,
//   daysToRelease,
//   daysToExpire,
// }) => {
//   return async (dispatch, getState) => {
//     const apiUrl = courseApi.chapter_by_id.url;
//     const headers = new Headers();
//     headers.append("Authorization", `Bearer ${token}`);
//     headers.append("Content-Type", "application/json");

//     const body = {
//       chapterId: Number(chapterId),
//       courseId: Number(courseId),
//       name: name,
//       order: Number(order),
//       postDate: postDate,
//       chapterOrigin: null,
//       tags: "",
//       daysToRelease: Number(daysToRelease),
//       daysToExpire: Number(daysToExpire),
//     };

//     try {
//       await fetch(`${apiUrl}`, {
//         method: "PUT",
//         headers: headers,
//         body: JSON.stringify(body),
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

function setLoading(value) {
  return { type: value ? SET_LOADING : CLEAR_LOADING };
}

export const removeChapter = ({ chapterId, token }) => {
  //implementar api pra remover capitulo e receber lista de capitulos atuais
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const currentList = getState().editing.chapterList;
    // const currentSelected = getState().editing.selectedChapterId;
    // const updateChapters = getState().editing.updateChapters;
    // const removeSelected = Number(currentSelected) === Number(chapterId);
    // const previousChapter = currentSelected - 1;
    const chapterIndex = currentList.findIndex(
      (chapter) => Number(chapter.chapterId) === Number(chapterId)
    );
    const apiUrl = courseApi.chapter_by_id.url;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("domain", domain);

    try {
      await fetch(`${apiUrl}?chapterId=${chapterId}`, {
        method: "DELETE",
        headers: headers,
        domain,
      });

      if (chapterIndex !== -1) {
        currentList.splice(chapterIndex, 1);
      }

      dispatch({
        type: REMOVE_CHAPTER,
        chapterList: [...currentList],
        selectedChapterId: null,
        // updateChapters: updateChapters + 1,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setSelectedChapter = ({
  chapterId,
  token,
  isChannel = false,
  isNews = false,
  isTutorials = false,
}) => {
  return async (dispatch, getState) => {
    const allChapters = isChannel
      ? getState().channel.items
      : isNews
      ? getState().news.items
      : isTutorials
      ? getState().tutorials.chapters
      : getState().course.chapters;
    const chapterInfo =
      isChannel || isNews
        ? allChapters?.find(({ CAPITULOID }) => CAPITULOID === chapterId)
        : allChapters?.find(({ capituloid }) => capituloid === chapterId);

    const currentList = getState().editing.chapterList;
    const fetchedChapter = currentList?.find(
      (chapter) => chapter.chapterId === chapterId
    );
    if (fetchedChapter?.chapterId) {
      dispatch({
        type: SELECT_FETCHED_CHAPTER,
        selectedChapterId: fetchedChapter.chapterId,
        loading: false,
      });
    } else {
      // if (!fetchedChapter?.chapterId) {
      dispatch({
        type: CHANGE_CHAPTER,
        selectedChapterId: chapterId,
        loading: true,
      });

      const loadedChapter = await fetchChapterContent(chapterId, token);

      dispatch({
        type: SELECT_UNFETCHED_CHAPTER,
        selectedChapterId: chapterId,
        chapterList: [
          ...currentList,
          {
            chapterId,
            contents: [...loadedChapter],
            type: chapterInfo?.tipo,
            surveyId: chapterInfo?.avaliacaoid,
            scormPackageId: chapterInfo?.scorm_pacoteid,
          },
        ],
        loading: false,
      });
    }
  };
};

// export const getChapterData = ({capituloid, disciplinaid, token, alunoid}) =>{
//   return async (dispatch) => {
//     const courseUri = disciplinaid > 900000
//       ? api.dados_disciplina_convenio
//       : api.dados_disciplina;

//     const chapterListUri = disciplinaid > 900000
//       ? api.lista_capitulos_convenio
//       : api.lista_capitulos;

//     const isExtraCourse = !!alunoid;

//     try {
//       const chapterContent = await fetch(`http://cadernovirtual.inf.br:8080/courses-service/content?chapterId=${capituloid}`, {
//         method: "get",
//         headers: { token },
//       })

//       const chapterJson = await chapterContent.json();

//       const courseData = isExtraCourse? async () => {
//         const extrasResponse = await fetch(`${api.disciplinas_extras}?alunoid=${alunoid}`, {
//           method: "get",
//           headers: { token }
//         });
//         const convenioResponse = await fetch(`${api.disciplinas_extras_convenio}?alunoid=${alunoid}`, {
//           method: "get",
//           headers: { token }
//         });
//         const extrasJson = await extrasResponse.json();
//         const convenioJson = await convenioResponse.json();
//         return [...(extrasJson[0].data || []), ...(convenioJson[0].data || []),];
//       }: await fetch(``,{

//       })

//       dispatch({
//         type: FETCH_CHAPTER_CONTENT,
//         chapterData: chapterJson,
//         courseData:{...courseData, chapterList:[]}
//       });

//     } catch (error) {
//       console.log("Erro ao buscar dados:", error);
//     }
//   }
// }

export const createContent = ({ type }) => {
  return (dispatch, getState) => {
    const currentList = getState().editing.chapterList;
    const selected = getState().editing.selectedChapterId;
    const chapterIndex = currentList.findIndex(
      (chapter) => Number(chapter.chapterId) === Number(selected)
    );
    currentList[chapterIndex].contents = [
      ...currentList[chapterIndex].contents,
      {
        contentTitle: "Título novo conteúdo",
        contentType: type.toUpperCase(),
        content: "",
        positionX: 0,
        positionY: 0,
        unsaved: true,
      },
    ];

    dispatch({
      type: CREATE_CONTENT,
      chapterList: [...currentList],
    });
  };
};

export const saveContent = ({ content, file = null }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));

      Object.defineProperty(
        content,
        "heigth",
        Object.getOwnPropertyDescriptor(content, "height")
      );
      delete content["height"];

      const payload = content;

      if (content.contentType === "ANEXO" || content.contentType === "IMAGEM") {
        const resourceToInclude = [];
        const resourceToExclude = [];

        file.forEach((attachment) => {
          if (attachment.isValid) {
            if (attachment?.condition === "removed") {
              resourceToExclude.push({
                resourceId: attachment.resourceId,
              });
            } else if (attachment?.modified) {
              resourceToInclude.push({
                resourceId: attachment.resourceId,
                contentResourceId: attachment.contentResourceId,
                mandatory: attachment?.mandatory || "N",
              });
            }
          } else {
            resourceToInclude.push({
              resourceId: attachment.resourceId,
              mandatory: attachment?.mandatory || "N",
            });
          }
        });

        if (payload.resource) {
          delete payload.resource;
        }

        if (resourceToInclude.length > 0)
          payload.resourceToInclude = resourceToInclude;

        if (resourceToExclude.length > 0)
          payload.resourceToExclude = resourceToExclude;
      }

      const body = new FormData();
      const contentBlob = new Blob([`${JSON.stringify(payload)}`], {
        type: "application/json",
      });

      body.append("content", contentBlob);

      if (
        file &&
        content.contentType !== "ANEXO" &&
        content.contentType !== "IMAGEM"
      ) {
        const fileBlob = new Blob([file], {
          type: file?.type,
        });

        body.append("file", fileBlob, file?.name);
      }

      const header = new Headers();
      header.append("Access-Control-Allow-Origin", "*");
      header.append("domain", domain);

      const response = await fetch(courseApi.save_chapter_content.url, {
        method: "POST",
        body: body,
        headers: header,
      });

      const { object: newContent } = await response.json();

      if (content.contentType === "ANEXO" || content.contentType === "IMAGEM") {
        newContent.content = newContent.resource.map((item) => ({
          ...item,
          RECURSOID: item.resourceId,
          CAPITULOID: item.chapterContentId,
          NOME: item.name,
          LINK: item.link,
          EXTENSAO: item.link?.split(".")?.at(-1) ?? "url",
          isValid: true,
        }));

        delete newContent.resource;
      }

      const list = [...getState().editing.chapterList];

      const chapterIndex = list.findIndex(
        ({ chapterId }) =>
          chapterId === newContent?.chapter?.id ||
          chapterId === newContent?.chapterId
      );

      const contentIndex = list[chapterIndex].contents.findIndex(
        ({ chapterContentId }) =>
          chapterContentId === newContent.chapterContentId
      );

      delete newContent.chapter;

      if (contentIndex !== -1) {
        list[chapterIndex].contents[contentIndex] = newContent;
      } else {
        list[chapterIndex].contents.push(newContent);
      }

      dispatch({
        type: SAVE_CONTENT,
        chapterList: list,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
      return;
    }
  };
};

export const removeContent = (contentId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      await fetch(
        courseApi.delete_chapter_content.url + `idContend=${contentId}`,
        {
          method: "DELETE",
          headers: { "Access-Control-Allow-Origin": "*", domain },
        }
      );

      const currentList = getState().editing.chapterList;
      const chapterId = getState().editing.selectedChapterId;
      const chapterIndex = currentList.findIndex(
        (chapter) => Number(chapter.chapterId) === Number(chapterId)
      );
      const contentIndex = currentList[chapterIndex].contents.findIndex(
        ({ chapterContentId }) => Number(chapterContentId) === Number(contentId)
      );

      currentList[chapterIndex].contents.splice(contentIndex, 1);

      dispatch({
        type: REMOVE_CONTENT,
        chapterList: [...currentList],
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };
};

const updateChapter = (chapter) => {
  return async (dispatch, getState) => {
    const {
      chapterId,
      courseId,
      name,
      order,
      tags,
      daysToRelease,
      daysToExpire,
    } = chapter;
    try {
      dispatch(setLoading(true));

      const update = {};
      update.chapterId = chapterId;
      update.courseId = courseId;

      if (name) update.name = name;
      if (order) update.order = order;
      if (tags) update.tags = tags;
      if (daysToRelease) update.daysToRelease = daysToRelease;
      if (daysToExpire) update.daysToExpire = daysToExpire;

      const response = await axios.put(courseApi.chapter_by_id.url, update, {
        headers: { "Access-Control-Allow-Origin": "*", domain },
      });

      const editedChapter = response.data;

      dispatch({
        type: UPDATE_CHAPTER,
        chapterList: [],
      });
      return editedChapter;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const moveChapter = (chapter, newOrder) =>
  updateChapter({ ...chapter, order: newOrder + 1 });

export const moveChapterUp = (chapter) => {
  return async (dispatch) => {
    try {
      const order = chapter.order;
      chapter.order--;
      await updateChapter(chapter)(dispatch);
      dispatch({ type: MOVE_CHAPTER_UP, payload: order });
    } catch (err) {
      throw err;
    }
  };
};

export const moveChapterDown = (chapter) => {
  return async (dispatch) => {
    try {
      const order = chapter.order;
      chapter.order++;
      await updateChapter(chapter)(dispatch);
      dispatch({ type: MOVE_CHAPTER_DOWN, payload: order });
    } catch (err) {
      throw err;
    }
  };
};

export const loadScormPackages = () => async (dispatch) => {
  try {
    const response = await axios.get(courseApi.get_scorm_packages.url, {
      headers: { "Access-Control-Allow-Origin": "*", domain },
    });

    dispatch({
      type: LOAD_SCORM_PACKAGES,
      payload: response.data.object,
    });
  } catch (error) {
    console.error(error);
  }
};

const INITIAL_STATE = {
  chapterList: [],
  selectedChapterId: null,
  updateChapters: 0,
  scormPackages: [],
  isLoading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_CHAPTER:
      return {
        ...state,
        chapterList: action.chapterList,
        selectedChapterId: action.selectedChapterId,
      };
    case REMOVE_CHAPTER:
      return {
        ...state,
        selectedChapterId: action.selectedChapterId,
        chapterList: action.chapterList,
      };
    case CHANGE_CHAPTER:
    case SELECT_FETCHED_CHAPTER:
      return {
        ...state,
        selectedChapterId: action.selectedChapterId,
        loading: action.loading,
      };
    case SELECT_UNFETCHED_CHAPTER:
      return {
        ...state,
        selectedChapterId: action.selectedChapterId,
        chapterList: action.chapterList,
        loading: action.loading,
      };
    case UPDATE_CHAPTER:
      return {
        ...state,
        chapterList: action.chapterList,
        selectedChapterId: null,
      };
    case CREATE_CONTENT:
      return {
        ...state,
        chapterList: action.chapterList,
      };
    case SAVE_CONTENT:
      return {
        ...state,
        chapterList: action.chapterList,
      };
    case REMOVE_CONTENT:
      return {
        ...state,
        chapterList: action.chapterList,
      };
    case LOAD_SCORM_PACKAGES:
      return {
        ...state,
        scormPackages: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CLEAR_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
