import { useCallback } from "react";
import { TargetBox } from "./TargetBox";
import { Box, FormHelperText, LinearProgress, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import VideosList from "./VideosList";
function ControlledVideoInput({
  progress = [],
  accept = "video/*",
  multiple = false,
  loading = false,
  control,
  register,
  helperText = "",
  error = false,
  name,
}) {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name,
  });
  // console.log({ fields });
  const handleFileDrop = useCallback(
    (item) => {
      if (item) {
        const files = Array.from(item.files).filter((file) =>
          file.type.includes("video")
        );
        // console.log({ files });
        if (files.length === 0) {
          return;
        }
        if (multiple) {
          append(
            files.map((file) => ({
              // fileBlob: new Blob([file], { type: file?.type }),
              file,
              title: file.name,
              type: file.type,
              name: file.name,
              size: file.size,
            }))
          );
        } else {
          replace(
            files.map((file) => ({
              // fileBlob: new Blob([file], { type: file?.type }),
              file,
              title: file.name,
              type: file.type,
              name: file.name,
              size: file.size,
            }))
          );
        }
      }
    },
    [fields]
  );

  const handleDeleteFile = useCallback(
    (deletedItemIndex) => {
      remove(deletedItemIndex);
    },
    [fields]
  );
  return loading ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Typography variant="h6"> Aguarde um momento...</Typography>
      <LinearProgress sx={{ width: "160px" }} />
    </Box>
  ) : (
    <>
      <TargetBox
        accept={accept}
        renderList={(fileInputRef) => {
          return (
            <VideosList
              fileInputRef={fileInputRef}
              files={fields.map((field) => field.file)}
              onDelete={handleDeleteFile}
              multiple={multiple}
              register={register}
              name={name}
              progress={progress}
            />
          );
        }}
        files={fields.map((field) => field.file)}
        multiple={multiple}
        onDrop={handleFileDrop}
        onDelete={handleDeleteFile}
        error={error}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </>
  );
}

export default ControlledVideoInput;
