import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { useSaveSurvey } from "../api/saveSurvey";
import moment from "moment";
import RichTextInput from "../../../ui/components/RichText/RichTextInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { AttachFile, Close } from "@mui/icons-material";
import { useState } from "react";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import { resourcesUrl } from "../../../shared/config";
import { DateTimePicker } from "@mui/x-date-pickers";
import CustomTextField from "../../../ui/components/Form/TextField";

const PRIVATE = "PRIVATE";
const PUBLIC = "PUBLIC";
const MANDATORY = "MANDATORY";
const NONMANDATORY = "NONMANDATORY";
const LOGIN = "LOGIN";
const LINK = "LINK";
const MANUAL = "MANUAL";
const MULTIPLEANSWERS = "MULTIPLEANSWERS";
const SINGLEANSWER = "SINGLEANSWER";

const createSurveySchema = yup.object().shape({
  title: yup.string().required("Esse campo é obrigatório"),
  orientations: yup.string().required("Esse campo é obrigatório"),
  startDate: yup.mixed(),
  endDate: yup
    .mixed()
    .test(
      "valid-time",
      "A de término deve ser posterior à data de início",
      (value, context) => {
        return value.isAfter(context.parent.startDate);
      }
    ),
  mandatory: yup.string(),
  npsType: yup.string(),
  anonymous: yup.string(),
  accessMode: yup.string(),
  accessType: yup.string(),
  multipleAswers: yup.string(),
  typeSummary: yup.string(),
});

const ACCECPTED_IMG_FILE_TYPES = [".gif", ".jpg", ".jpeg", ".png"];

export function SaveSurveyForm({
  defaultValues = {
    title: "Nova pesquisa",
    orientations: "",
    startDate: moment(new Date()),
    endDate: moment(new Date()).endOf("day"),
    mandatory: "S",
    npsType: "N",
    accessMode: "LOGON",
    accessType: "PRIVADO",
    multipleAswers: "N",
    typeSummary: `${PRIVATE}_${MANDATORY}_${LOGIN}_${SINGLEANSWER}`,
  },
  onSuccess = () => {},
  onDirty = () => {},
  submitButtonRef,
}) {
  const saveSurveyMutation = useSaveSurvey({
    config: {
      onSuccess: (data, variables, context) => {
        onSuccess(data, variables, context);
        onDirty(false);
      },
    },
  });
  const ref = useRef();
  const [image, setImage] = useState(defaultValues.image);
  const handleFile = (e) => {
    setImage(e.target.files[0]);
    onDirty(true);
  };

  const formMethods = useForm({
    defaultValues: {
      ...defaultValues,
      orientations: defaultValues.orientations.replace(/(\r\n|\n|\r)/gm, ""),
    },
    resolver: yupResolver(createSurveySchema),
  });

  useEffect(() => {
    onDirty(!!Object.keys(formMethods.formState.dirtyFields).length);
  }, [formMethods.formState.dirtyFields]);

  const handleTypeChange = (event) => {
    const features = event.target.value.split("_");
    formMethods.setValue("typeSummary", event.target.value);
    features.map((feature) => {
      switch (feature) {
        case PRIVATE:
          formMethods.setValue("accessType", "PRIVADO", { shouldDirty: true });
          return;
        case PUBLIC:
          formMethods.setValue("accessType", "PUBLICO", { shouldDirty: true });
          return;
        case MANDATORY:
          formMethods.setValue("mandatory", "S", { shouldDirty: true });
          return;
        case NONMANDATORY:
          formMethods.setValue("mandatory", "N", { shouldDirty: true });
          return;
        case LOGIN:
          formMethods.setValue("accessMode", "LOGON", { shouldDirty: true });
          return;
        case LINK:
          formMethods.setValue("accessMode", "LINK", { shouldDirty: true });
          return;
        case MANUAL:
          formMethods.setValue("accessMode", "NULL", { shouldDirty: true });
          return;
        case MULTIPLEANSWERS:
          formMethods.setValue("multipleAnswers", "S", { shouldDirty: true });
          return;
        case SINGLEANSWER:
          formMethods.setValue("multipleAnswers", "N", { shouldDirty: true });
          return;
        default:
          return;
      }
    });
  };

  const disableNPS =
    defaultValues.npsType == "N" && !!defaultValues.questions?.length;
  return (
    <>
      <form
        onSubmit={formMethods.handleSubmit((values) => {
          saveSurveyMutation.mutate({
            data: {
              ...values,
              orientations: encodeURIComponent(values.orientations),
              questions: undefined,
              startDate: values.startDate.format("DD/MM/YYYY HH:mm:ss"),
              endDate: values.endDate.format("DD/MM/YYYY HH:mm:ss"),
            },
            image,
          });
        })}
      >
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
          <CustomTextField
            formMethods={formMethods}
            fieldName={"title"}
            labelText="Título"
          />
          <input
            accept={ACCECPTED_IMG_FILE_TYPES.toString()}
            ref={ref}
            type="file"
            hidden
            onChange={handleFile}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              sx={{ width: "fit-content", height: "fit-content" }}
              startIcon={<AttachFile />}
              onClick={() => ref.current.click()}
            >
              Adicionar imagem
            </Button>
            {image && (
              <Box
                sx={{
                  position: "relative",
                  aspectRatio: "16 / 9",
                  height: "200px",
                  width: "auto",
                }}
              >
                <IconButton
                  onClick={() => setImage(null)}
                  sx={{
                    position: "absolute",
                    top: 3,
                    right: 3,
                    zIndex: "1",
                    border: "1px solid var(--primary_color_light)",
                    backgroundColor: "white",
                  }}
                >
                  <Close />
                </IconButton>

                <Box
                  component="img"
                  sx={{ width: "100%", height: "100%", objectFit: "contain" }}
                  src={
                    typeof image == "string"
                      ? resourcesUrl + "/recursos/" + image
                      : URL.createObjectURL(image)
                  }
                />
              </Box>
            )}
          </Box>
          <FormControl
            sx={{
              border: `1px solid ${
                formMethods.formState.errors["orientations"] ? "red" : "#e5e7eb"
              }`,
              borderRadius: 1,
              "&:hover": { border: "1px solid black" },
            }}
            helperText={"Teste"}
            error={formMethods.formState.errors["orientations"]?.message}
          >
            <InputLabel
              sx={{ transform: "translate(14px, -15px) scale(0.75)" }}
              shrink
              htmlFor="my-input"
            >
              Descrição
            </InputLabel>
            <Controller
              control={formMethods.control}
              name={"orientations"}
              render={({ field }) => {
                return (
                  <RichTextInput
                    id="my-input"
                    value={field.value}
                    onChange={field.onChange}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "code-block",
                        ],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        // ["link", "image"],
                      ],
                    }}
                  />
                );
              }}
            />
            <FormHelperText>
              {formMethods.formState.errors["orientations"]?.message}
            </FormHelperText>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Controller
              control={formMethods.control}
              name="startDate"
              render={({ field }) => {
                return (
                  <DateTimePicker
                    views={[
                      "year",
                      "month",
                      "day",
                      "hours",
                      "minutes",
                      "seconds",
                    ]}
                    slotProps={{
                      textField: {
                        error: !!formMethods.formState.errors["startDate"],
                        helperText:
                          formMethods.formState.errors["startDate"]?.message,
                      },
                    }}
                    sx={{ flexGrow: "1" }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label="Data de início"
                    value={field.value}
                    onChange={field.onChange}
                    placeHolderText="Data de início"
                  />
                );
              }}
            />
            <Controller
              control={formMethods.control}
              name="endDate"
              render={({ field }) => {
                return (
                  <DateTimePicker
                    views={[
                      "year",
                      "month",
                      "day",
                      "hours",
                      "minutes",
                      "seconds",
                    ]}
                    slotProps={{
                      textField: {
                        error: !!formMethods.formState.errors["endDate"],
                        helperText:
                          formMethods.formState.errors["endDate"]?.message,
                      },
                    }}
                    sx={{ flexGrow: "1" }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label="Data final"
                    value={field.value}
                    onChange={field.onChange}
                    placeHolderText="Data final"
                  />
                );
              }}
            />
          </Box>

          <TextField
            select
            value={formMethods.watch("typeSummary")}
            onChange={handleTypeChange}
          >
            <MenuItem
              value={`${PRIVATE}_${MANDATORY}_${LOGIN}_${SINGLEANSWER}`}
            >
              Pesquisa privada obrigatória no login (resposta única)
            </MenuItem>
            <MenuItem
              value={`${PRIVATE}_${NONMANDATORY}_${LOGIN}_${SINGLEANSWER}`}
            >
              Pesquisa privada não obrigatória no login (resposta única)
            </MenuItem>
            <MenuItem
              value={`${PRIVATE}_${NONMANDATORY}_${LINK}_${SINGLEANSWER}`}
            >
              Pesquisa privada não obrigatória com link (resposta única)
            </MenuItem>
            <MenuItem
              value={`${PRIVATE}_${NONMANDATORY}_${LINK}_${MULTIPLEANSWERS}`}
            >
              Pesquisa privada não obrigatória com link (múltipla respostas)
            </MenuItem>
            <MenuItem
              value={`${PUBLIC}_${NONMANDATORY}_${LINK}_${MULTIPLEANSWERS}`}
            >
              Pesquisa pública não obrigatória com link (múltipla respostas)
            </MenuItem>
          </TextField>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              label="Pesquisa NPS"
              control={
                <Controller
                  control={formMethods.control}
                  name="npsType"
                  disabled={disableNPS}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        disabled={disableNPS}
                        checked={field.value == "S"}
                        onChange={(event) => {
                          return formMethods.setValue(
                            "npsType",
                            event.target.checked ? "S" : "N",
                            { shouldDirty: true }
                          );
                        }}
                      />
                    );
                  }}
                />
              }
            />
            <FormControlLabel
              label="Anônima"
              control={
                <Controller
                  control={formMethods.control}
                  name="anonymous"
                  render={({ field }) => {
                    return (
                      <Checkbox
                        checked={field.value == "S"}
                        onChange={(event) => {
                          return formMethods.setValue(
                            "anonymous",
                            event.target.checked ? "S" : "N",
                            { shouldDirty: true }
                          );
                        }}
                      />
                    );
                  }}
                />
              }
            />
          </Box>
          <button
            hidden
            ref={submitButtonRef}
            loading={saveSurveyMutation.isLoading}
            type="submit"
          >
            Salvar
          </button>
        </Box>
      </form>
    </>
  );
}
