import { Box, Modal } from "@mui/material";

import { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";

import _cookiesChunked from "../../../../../../shared/utils/_cookiesChunked";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PreviewMarker from "../../partials/PreviewMarker";
import { useRegisterView } from "../../../../../../features/quadrants/api/registerView";
import { useQuadrantViewedStatus } from "../../../../../../features/quadrants/api/getQuadrantViewedStatus";

function VideoContent({
  app,
  contentId,
  mandatory,
  sx = {},
  url,
  hideControls,
  children,
  isEditing,
  auth,
  enrollment,
  chapters,

  ...props
}) {
  const { currentChapter } = useParams();
  const defaultTime = 2 * 1000 * 60; // 2 minutos

  const [isOpen, setIsOpen] = useState(false);

  const videoData = _cookiesChunked.getItem("params");
  window.pandascripttag = window.pandascripttag || [];
  const player = new window.PandaPlayer("panda-video", {
    onReady: () => {},
    onError: (event) => {
      // console.error("Player onError", event);
    },
  });

  const registerViewMutation = useRegisterView({});

  const quadrantViewedQuery = useQuadrantViewedStatus({
    chapterId: currentChapter,
    enrollmentId: enrollment?.MATRICULAID,
    quadrantId: contentId,
  });

  const handleEnded = () => {
    if (!isEditing && mandatory === "S" && !quadrantViewedQuery.data?.viewed) {
      registerViewMutation.mutate({
        enrollmentId: enrollment?.MATRICULAID,
        chapterId: currentChapter,
        quadrantId: contentId,
      });
    }
  };

  const isYoutube = url?.search(/youtu/) > -1;
  const youtubeHiddenControlsURL = new URL(url || "http://teste.com");
  youtubeHiddenControlsURL.searchParams.set("controls", 0);
  const isPandaVideo = url?.search(/pandavideo/) > -1;
  const isJmv = url?.search(/player.jmvstream/) > -1;
  const onReceiveMessage = useCallback(
    (event) => {
      const { data } = event;
      const thisVideoId = url?.split("v=")?.pop();

      switch (data.message) {
        case "panda_play":
          if (app?.params.PAUSAR_VIDEO_PERIODICAMENTE != 0) {
            setTimeout(() => {
              player.pause();
              setIsOpen(true);
            }, videoData?.TEMPO_PAUSE_VIDEO || app?.params.PAUSAR_VIDEO_PERIODICAMENTE * 1000 * 60 || defaultTime);
          }
          break;
        case "panda_allData":
          break;
        case "panda_progress":
          break;
        case "panda_timeupdate":
          break;
        case "panda_ended":
          if (thisVideoId === data?.video) {
            handleEnded();
          }

          break;
        default:
        // console.log(data.message);
      }
    },
    [handleEnded, url]
  );

  useEffect(() => {
    window.addEventListener("message", onReceiveMessage);
    if (player) {
      window.addEventListener("blur", player.pause);
    }
    return () => {
      window.removeEventListener("message", onReceiveMessage);
      window.addEventListener("blur", player.pause);
    };
  }, [isPandaVideo, onReceiveMessage, mandatory]);

  const basicControls = [
    "play-large", //botão de play no meio da tela
    "play", //botão de play/pause no canto inferior esquerdo
    "volume", //controle de volume
    "captions", //controle de legendas
    "pip", //ativar/desativar player in picture
    "fullscreen", //ativar/desativar tela cheia
  ];

  const additionalControls = [
    "progress", //barra de progresso (tempo) do video
    "current-time", //indicador do tempo total e atual do video
    "settings", //controle de qualidade (360p, 480p, 720p, 1080p, etc...), velocidade e report de erro
  ];

  const allControls = [...basicControls, ...additionalControls]?.join(",");

  const playControls = basicControls.join(",");

  return (
    <>
      {isPandaVideo ? (
        <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
          <iframe
            key="panda-video"
            id="panda-video"
            title="Panda"
            src={url + `&controls=${hideControls ? playControls : allControls}`}
            width="100%"
            style={{ aspectRatio: "auto 16/9", objectFit: "content" }}
            allowFullScreen="true"
            // disablePictureInPicture
          />
          {!isEditing && mandatory === "S" && (
            <Box
              sx={{
                position: "absolute",
                top: "0",
              }}
            >
              <PreviewMarker viewed={quadrantViewedQuery.data?.viewed} />
            </Box>
          )}
        </Box>
      ) : isJmv ? (
        <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
          <iframe
            key="jmv"
            title="jmv"
            src={url}
            width="100%"
            style={{ aspectRatio: "auto 16/9", objectFit: "content" }}
            allowFullScreen="true"
          />
        </Box>
      ) : (
        <Box
          sx={{
            aspectRatio: "16 / 9",
            width: "100%",
            height: "auto",
            iframe: { width: "100%", height: "100%" },
            position: "relative",
          }}
        >
          {!isEditing && mandatory === "S" && (
            <Box
              sx={{
                position: "absolute",
                top: "0",
              }}
            >
              <PreviewMarker viewed={quadrantViewedQuery.data?.viewed} />
            </Box>
          )}
          {isYoutube && hideControls ? (
            <ReactPlayer
              url={youtubeHiddenControlsURL.href}
              width="100%"
              height="100%"
              // controls={!hideControls}
              onEnded={handleEnded}
              config={{
                playerVars: {
                  playinline: 1,
                },
              }}
            />
          ) : (
            <ReactPlayer
              url={url}
              width="100%"
              height="100%"
              controls
              onEnded={handleEnded}
              config={{
                playerVars: {
                  playinline: 1,
                },
              }}
            />
          )}
        </Box>
      )}

      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="w-screen	h-screen"
      >
        <div className="bg-white absolute translate-y-1/2 translate-x-1/2 bottom-1/2 right-1/2 w-full mq-900:w-1/2 h-screen mq-900:h-1/2 rounded shadow flex flex-col justify-around items-center">
          <h1 className="text-xl">Ainda está aqui?</h1>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setIsOpen(false)}
          >
            Continuar
          </button>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  enrollment: state.course?.enrollment,
  chapters: state.course?.chapters,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VideoContent);
