import { Box, Button, styled, useTheme } from "@mui/material";
import { baseUrl } from "../../../../../../shared/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import PreviewMarker from "../../partials/PreviewMarker";
import { useRef } from "react";
import getFileExtension from "../../../../../../shared/utils/getFileExtension";
import { useRegisterView } from "../../../../../../features/quadrants/api/registerView";
import { useQuadrantViewedStatus } from "../../../../../../features/quadrants/api/getQuadrantViewedStatus";

const StyledEmbed = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

function DocContent({
  contentId,
  mandatory,
  value,
  auth,
  enrollment,
  chapters,
}) {
  const { currentChapter } = useParams();
  const quadrantViewedStatusQuery = useQuadrantViewedStatus({
    quadrantId: contentId,
    chapterId: currentChapter,
    enrollment: enrollment?.MATRICULAID,
  });
  const registerViewMutation = useRegisterView();
  const theme = useTheme();

  const viewedList =
    chapters?.find((chapter) => chapter?.capituloid === Number(currentChapter))
      ?.views || [];

  const isContentViewed = () =>
    viewedList?.find((viewedObj) => viewedObj.QUADRANTEID === contentId);

  const handleClickView = () => {
    if (!quadrantViewedStatusQuery.data?.viewed) {
      registerViewMutation.mutate({
        enrollmentId: enrollment?.MATRICULAID,
        quadrantId: currentChapter,
      });
    }
  };

  const documentUrl = !!value
    ? value?.includes("https")
      ? value
      : baseUrl + "/" + value
    : "";

  const officeDocExtensions = [
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
  ];

  const isOffice =
    !!documentUrl &&
    officeDocExtensions.some((extension) => {
      return getFileExtension(documentUrl).toLowerCase() === extension;
    });

  const formattedUrl = isOffice
    ? `https://view.officeapps.live.com/op/embed.aspx?src=${documentUrl}`
    : documentUrl;
  const ref = useRef();
  const linkRef = useRef();
  return (
    <Box
      sx={
        isOffice
          ? {
              position: "relative",
              width: "100%",
              height: "100%",
              // paddingBottom: "calc(k100%*(10/16))",
            }
          : {
              position: "relative",
              flexGrow: 1,
            }
      }
    >
      <Button></Button>
      <StyledEmbed
        ref={ref}
        sx={
          isOffice
            ? {
                position: "absolute",

                ...(getFileExtension(documentUrl).toLowerCase().includes("ppt")
                  ? {
                      aspectRatio: "16 / 9",
                      height: "100%",
                      // width: "100%",
                      width: "auto",
                    }
                  : {
                      height: "100%",
                      width: "100%",
                    }),
                //ESSE VALOR SERVE PRA CONCERTAR OS POWERPOINTS
                top: "0",
                left: "50%",
                transform: "translateX(-50%)",
              }
            : {
                height: "100%",
              }
        }
        src={formattedUrl}
      />

      {mandatory === "S" && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            cursor: "pointer",
          }}
          onClick={handleClickView}
        >
          <PreviewMarker viewed={quadrantViewedStatusQuery.data?.viewed} />
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  enrollment: state.course?.enrollment,
  chapters: state.course?.chapters,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocContent);
