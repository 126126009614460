import { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import useModule from "../../common/hooks/useModule";
import { useMediaQuery, useTheme } from "@mui/material";

function AbsoluteContentCard({
  id,
  type,
  onLoad,
  addItem = ({ row, column, id }) => {},
  getItemPosition = (id) => {},
  chapterId,
  enrollmentId,
  ...rest
}) {
  const [content, setContent] = useState(null);
  const [mandatory, setMandatory] = useState(false);
  const [options, setOptions] = useState(null);
  const [quadranteId, setQuadrantId] = useState(null);
  const [recursoId, setRecursoId] = useState(null);
  const [Component, { fetchModule }] = useModule({ id, type });
  const [fetchedModule, setFetchedModule] = useState(null);

  const theme = useTheme();
  const breakString = theme.breakpoints.up("md");
  const isDisplayLarge = useMediaQuery(breakString);

  const columnOnly = !isDisplayLarge;

  useEffect(() => {
    const mountComponent = async () => {
      try {
        const fetchedModule = fetchModule();
        const module = await fetchedModule;

        setFetchedModule(module);

        setMandatory(module.mandatory);
        setQuadrantId(module.chapterContentId);
        setRecursoId(module.resourceId);
        setOptions({
          removePlayerControl: module.removePlayerControl,
          tags: module.tags,
        });
        setContent(module.content);
      } finally {
        onLoad();
      }
    };

    mountComponent();
  }, [id]);

  const { column, row, isRow, isDouble } = fetchedModule || {};

  useEffect(() => {
    if (!!fetchedModule) {
      addItem({ ...fetchedModule, id });
    }
  }, [fetchedModule]);

  const isDocument = type === "DOCUMENTO";

  return (
    <Box
      sx={{
        gridColumn: columnOnly
          ? "1"
          : `${column + 1} / span ${isRow ? "2" : "1"}`,
        gridRow: columnOnly
          ? `${getItemPosition(id) + 1}`
          : `${row + 1} / span ${isDouble ? "2" : "1"}`,
        aspectRatio: isDocument
          ? `${isRow ? "2" : "1"} / ${isDouble ? "2" : "1"}`
          : "unset",
        overflow: "hidden",
      }}
    >
      <Component
        contentId={id}
        value={content}
        mandatory={mandatory}
        options={options}
        recursoId={recursoId}
        quadrantId={quadranteId}
        chapterId={chapterId}
        enrollmentId={enrollmentId}
      />
    </Box>
  );
}

export default AbsoluteContentCard;
