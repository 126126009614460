// https://cadernovirtual.inf.br/courses-service/content

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const registerView = async ({
  studentId,
  enrollmentId,
  quadrantId,
  resourceId = 0,
}) => {
  const body = new FormData();

  body.append(
    "dados",
    JSON.stringify([
      {
        alunoid: studentId,
        matriculaId: enrollmentId,
        quadranteid: quadrantId,
        recursoid: resourceId,
      },
    ])
  );
  const res = await axios.middleware.post(
    "/Backend.asmx/BK_Marca_Visualizacao",
    body
  );
  return res;
};

export const useRegisterView = ({ config } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: (data) =>
      registerView({
        studentId: _cookies.getItem("auth")?.user?.alunoid,
        ...data,
      }),
    onSuccess: (_, { chapterId, enrollmentId, quadrantId }) => {
      queryClient.invalidateQueries({
        queryKey: ["quadrantViewedStatus", { quadrantId }],
      });
    },
  });
};
