import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import { listAll } from "../../../shared/store/video";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Box, DialogTitle, IconButton } from "@mui/material";
import { CloseCircleOutline } from "mdi-material-ui";
import PandaVideos from "../../../features/misc/components/PandaVideos.";

const styles = (theme) => ({
  buttonProgress: {
    color: "red",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

function VideoList({
  user,
  params,
  open,
  onClose,
  handleClose,
  onSelect,
  onRemove,
  ...props
}) {
  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <IconButton onClick={onClose}>
          <CloseCircleOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PandaVideos onSelectVideo={onSelect} />
      </DialogContent>
      <DialogActions>
        {handleClose && <Button onClick={handleClose}>FECHAR</Button>}
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  video: state.video,
  params: state.app.params,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ listAll }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(VideoList));
