import { /*isBasf,*/ isLocal } from "./urlConfig";

const localhostClient = "aceleracredvalor";
export const folderBasename = isLocal ? "/aluno" : "/aluno";

export const getClient = () => {
  const host = window.location.host;
  if (isLocal) {
    return localhostClient;
  }
  switch (host) {
    case "onbook.martins.com.br":
      return "onbook";
    case "unimarche.marche.com.br":
      return "unimarche";
    default:
      const client = isLocal
        ? localhostClient
        : host
            .replace("www.", "")
            .replace("cadernovirtual", "")
            .replace("basf-academiaagro", "")
            .replace(".com.br", "")
            .replace(".", "")
            .replace("beta", "");
      return client;
  }
};

export var baseUrl = "https://" + window.location.host;
console.log({ baseUrl });

if (isLocal) {
  switch (localhostClient.toLocaleUpperCase()) {
    case "ONBOOK":
      baseUrl = `https://onbook.martins.com.br`;
      break;
    case "UNIMARCHE":
      baseUrl = `https://unimarche.marche.com.br`;
      break;
    default:
      baseUrl = `https://${localhostClient}.cadernovirtual.com.br`;
      break;
  }
}

export const logoUrl = `${baseUrl}/img/logo_cliente.png`;
export const logoAdicionalUrl = `${baseUrl}/img/`;
export const menuIconsUrl = `${baseUrl}/Menu_Icons/`;
export const certificate = `${baseUrl}/img/certificado_disciplina{disciplinaid}.jpg`;
export const certificateSignature = `${baseUrl}/Membros/fotos/ASSP{professorid}.png`;
export const certificateDefault = `${baseUrl}/img/certificado_mascara.jpg`;
export const membrosUrl = `${baseUrl}/Membros`;
export const imagesUrl = `${membrosUrl}/fotos`;
export const gamesImagesUrl = `${membrosUrl}/games/game`;
export const resourcesUrl = `${membrosUrl}/`;
export const scormUrl = `${membrosUrl}/SCORM`;
export const chatUrl = "https://cadernovirtual.inf.br/index.html";
export const postImageAluno = `${baseUrl}/membros/fotos/`;
export const configVimeo = {
  apiVimeo: "https://cadernovirtual.inf.br/server/api/vimeo",
  cvApiBaseUrl: `https://cvcorporate.cadernovirtual.com.br/api/vimeo/vimeo.asmx`, // url base da api
  user: { id: "10", type: "g", albumId: "5714229" /* "5639975" */ }, // dados do usuario
  apiUrl: "https://middleware.cadernovirtual.com.br",
};

// DATABASE
//export const database = "cvcorporate".toUpperCase();
export const database = getClient();
