import { AppBar, IconButton, Toolbar } from "@mui/material";
import { Close, Print } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";

import _cookiesChunked from "../../shared/utils/_cookiesChunked";
import { baseUrl, logoUrl } from "../../shared/config";

const params = _cookiesChunked.getItem("params");
const drawerWidth = 240;
const styles = (theme) => ({
  appBar: {
    width: "100%",
    marginLeft: 0,
    zIndex: 1150,
  },
  logosAppBarWrap: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    width: drawerWidth,
    padding: "12px 16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoWrap: {
    "&:nth-child(2)": {
      marginLeft: 8,
      paddingLeft: 8,
      borderLeft: "1px solid rgba(255, 255, 255, .2)",
    },
  },
  logoAppBar: {
    flexBasis: "content",
    maxWidth: "100%",
    height: "100%",
    objectFit: "contain",
  },
  actions: {
    marginLeft: "auto",
    marginRight: -15,
  },
  actionButton: {
    marginLeft: 10,
  },
});

const DialogAppBar = (
  classes,
  handleClose,
  showCloseButton = true,
  showPrintButton = false
) => {
  if (!classes.appBar) return null;
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <div className={classes.logosAppBarWrap}>
          <div className={classes.logoWrap}>
            <img className={classes.logoAppBar} src={logoUrl} alt="" />
          </div>
        </div>

        <div className={classes.actions}>
          {showPrintButton && (
            <IconButton
              className={classes.actionButton}
              edge="start"
              color="inherit"
              onClick={() => window.print()}
              aria-label="Fechar"
            >
              <Print />
            </IconButton>
          )}
          {showCloseButton && (
            <IconButton
              className={classes.actionButton}
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="Fechar"
            >
              <Close />
            </IconButton>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default withStyles(styles, { withTheme: true })(DialogAppBar);
