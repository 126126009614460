import Box from "@mui/material/Box";
import PreviewMarker from "../../partials/PreviewMarker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import ReactQuill from "react-quill";
import { useQuadrantViewedStatus } from "../../../../../../features/quadrants/api/getQuadrantViewedStatus";
import { useRegisterView } from "../../../../../../features/quadrants/api/registerView";

function TextContent({
  contentId,
  mandatory,
  sx,
  htmlText,
  text,
  children,
  auth,
  enrollment,
  chapters,
}) {
  const { currentChapter } = useParams();

  const viewedList =
    chapters?.find((chapter) => chapter?.capituloid === Number(currentChapter))
      ?.views || [];

  const quarantViewedStatusQuery = useQuadrantViewedStatus({
    chapterId: currentChapter,
    enrollmentId: enrollment?.MATRICULAID,
    quadrantId: contentId,
  });

  const registerViewMutation = useRegisterView();

  const handleClickView = () => {
    if (!quarantViewedStatusQuery.data?.viewed) {
      registerViewMutation.mutate({
        enrollmentId: enrollment.MATRICULAID,
        quadrantId: contentId,
      });
    }
  };
  const modules = {
    toolbar: false,
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        padding: "0 0 0 0",
      }}
    >
      {mandatory === "S" && (
        <span className="flex flex-row items-center">
          <Button
            sx={{
              top: "0",
              cursor: "pointer",
            }}
            onClick={handleClickView}
          >
            {" "}
            {quarantViewedStatusQuery.data?.viewed
              ? "Leitura Confirmada"
              : "Confirmar Leitura"}
          </Button>
          <PreviewMarker viewed={quarantViewedStatusQuery.data?.viewed} />
        </span>
      )}
      {htmlText ? (
        <div
          style={{
            marginTop: "0px",
            height: "inherit",
            width: "inherit",
            ...sx,
          }}
        >
          <ReactQuill modules={modules} value={htmlText} readOnly />
        </div>
      ) : (
        <Box sx={sx} height="inherit" width="inherit">
          {text || children}
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  enrollment: state.course?.enrollment,
  chapters: state.course?.chapters,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TextContent);
