import {
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TablePagination,
  TextField,
} from "@mui/material";
import GridList from "../../../ui/components/List/GridList";
import { usePandaVideos } from "../api/getPandaVideos";
import GridListItem from "../../../ui/components/List/GridListItem";
import { useState } from "react";
import { Check, ContentCopy, DeleteTwoTone } from "@mui/icons-material";
import DeletePandaVideo from "./DeletePandaVideo";
import PandaVideoUploader from "./PandaVideoUploader";

export default function PandaVideos({ onSelectVideo = () => {} }) {
  const [myVideos, setMyVideos] = useState(false);
  const [page, setPage] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(25);
  const pandaVideosQuery = usePandaVideos({
    params: { myVideos, page: page + 1, itensPerPage },
  });

  const handleSelectVideo = (video, index) => (e) => {
    onSelectVideo(video.videoId);
  };

  const handleChangeVideoFilter = (event) => {
    setMyVideos(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newValue) => {
    setPage(parseInt(newValue));
  };

  const handleChangeItensPerPage = (event) => {
    setItensPerPage(event.target.value);
    setPage(0);
  };

  if (pandaVideosQuery.isFetching && !pandaVideosQuery.isRefetching) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: { sm: "initial", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <PandaVideoUploader />
        <Box sx={{ display: { sm: "initial", md: "flex" } }}>
          <TextField select value={myVideos} onChange={handleChangeVideoFilter}>
            <MenuItem value={true}>Meus vídeos</MenuItem>
            <MenuItem value={false}>Todos os vídeos</MenuItem>
          </TextField>
          <TablePagination
            labelDisplayedRows={({ from, to, count, page }) => {
              return `${from}–${to} de ${count !== -1 ? count : ` ${to}`}`;
            }}
            labelRowsPerPage="Items por página"
            component="div"
            count={pandaVideosQuery.data?.totalItensList}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={itensPerPage}
            onRowsPerPageChange={handleChangeItensPerPage}
          />
        </Box>
      </Box>
      {pandaVideosQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <GridList>
          {pandaVideosQuery.data?.object?.map((video, index) => {
            return (
              <GridListItem
                CardProps={{
                  sx: {
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  },
                }}
                disabled
                ActionAreaProps={{
                  onClick: handleSelectVideo(video, index),
                  id: `video_card_ActionArea_${index}`,
                }}
                imageUrl={video.picture || "erro"}
                label={video.name}
                enableOptions
                options={({ closeMenu }) => [
                  <MenuItem
                    onClick={(e) => {
                      navigator.clipboard.writeText(video.videoId);
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <ContentCopy fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Copiar URL</ListItemText>
                  </MenuItem>,
                  <DeletePandaVideo video={video}>
                    {({ onClick, disabled, isLoading }) => {
                      return (
                        <MenuItem
                          onClick={onClick}
                          disabled={isLoading || disabled}
                        >
                          <ListItemIcon>
                            <DeleteTwoTone />
                          </ListItemIcon>
                          <ListItemText>Deletar</ListItemText>
                        </MenuItem>
                      );
                    }}
                  </DeletePandaVideo>,
                ]}
              />
            );
          })}
        </GridList>
      )}
    </Box>
  );
}
