import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import { createNotification } from "../../../shared/store/notifications";

export const deletePandaVideo = async (params) => {
  const res = await axios.middleware.delete(
    "https://api.cadernovirtual.com.br/video-service/video",
    { params }
  );
  return res;
};
export const useDeletePandaVideo = ({ config } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: deletePandaVideo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pandaVideos"] });
      createNotification({
        type: "success",
        message: "Vídeo deletado com sucesso",
      });
    },
  });
};
